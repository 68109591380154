import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

import { TitleAndMetaTags } from '../components/Helpers.TitleAndMetaTags'
import { Layout } from '../components/Layout'
import { SmallWrapper, FullWidthWrapper } from '../components/Layout.Wrapper'
import { Spacer } from '../components/Layout.Spacer'
import { Image } from '../components/Common.Image'
import { ButtonContainer, Button } from '../components/Common.Button'
import { IdLink } from '../components/Common.IdLink'
import { H2, H3, H4, HugeHeading } from '../components/Typography.Headings'

import hifilabs from '../../static/assets/hifilabs.png'

import { Store } from '../containers/Store'
import { Artists } from '../containers/Artists'
import { Email } from '../containers/Email'
import { VideoContainer } from '../containers/VideoContainer'
import { Primes } from '../containers/Primes'

export const IndexPageContent = ({ data }) => {
  const { config } = data
  const {
    dateRow,
    whereRow,

    storeTitle,
    storeText,

    donateTitle,
    donateText,
    donateButtonText,
    donateButtonLink,

    emailButton,
    emailText,
    emailTitle,
  } = config

  return (
    <Fragment>
      <Spacer height={100} />
      <SmallWrapper alignment="center"></SmallWrapper>
      <VideoContainer />
      <SmallWrapper alignment="center">
        <ButtonContainer>
          <a href={'#store'}>
            <Button>ENTER CRYPT</Button>
          </a>
        </ButtonContainer>
      </SmallWrapper>

      <Spacer height={50} />

      <SmallWrapper
        alignment="center"
        backgroundColor="black"
        style={{
          backgroundSize: '20px 20px',
          backgroundImage:
            'repeating-linear-gradient(-45deg, rgba(115,0,255,0.25) 0, rgba(115,0,255,0.05) 6px, transparent 0, transparent 50%)',
        }}
      >
        <IdLink id={'donate'} />
        <HugeHeading align="center" color="purple">
          {donateTitle}
        </HugeHeading>
        <H4 align="center" color="purple">
          {donateText}
        </H4>
        <a href={donateButtonLink} target="_blank" rel="noreferrer">
          <Button>{donateButtonText}</Button>
        </a>
      </SmallWrapper>

      <Spacer height={150} />

      <FullWidthWrapper>
        <IdLink id={'artists'} />
        <SmallWrapper>
          <Artists />
        </SmallWrapper>
      </FullWidthWrapper>

      <Spacer height={100} />

      <FullWidthWrapper alignment="center">
        <IdLink id={'store'} />
        <HugeHeading align="center" color="purple">
          {storeTitle}
        </HugeHeading>
        <H4 align="center" color="purple">
          {storeText}
        </H4>
        <Spacer height={50} />
        <SmallWrapper>
          <Store />
        </SmallWrapper>
      </FullWidthWrapper>

      <Spacer height={225} />

      <FullWidthWrapper backgroundColor="purple">
        <Primes />
      </FullWidthWrapper>

      <Spacer height={125} />

      <SmallWrapper alignment="center">
        <IdLink id={'email'} />
        <HugeHeading align="center" color="purple">
          {emailTitle}
        </HugeHeading>
        <H4 align="center" color="purple">
          {emailText}
        </H4>
        <Spacer height={50} />
        <Email emailButton={emailButton} />
      </SmallWrapper>

      <Spacer height={125} />

      <SmallWrapper alignment="center">
        <a href={'https://hifilabs.co'} target="_blank" rel="noreferrer">
          <H4 align="center" color="purple" style={{ maxWidth: 500 }}>
            powered by{' '}
            <Image
              src={hifilabs}
              alt="appleVille"
              width="125px"
              style={{ verticalAlign: 'middle ' }}
            />
          </H4>
        </a>
      </SmallWrapper>
    </Fragment>
  )
}

export default function IndexPage({ data, location }) {
  return (
    <Layout location={location}>
      <TitleAndMetaTags />
      <IndexPageContent data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query homeQuery {
    markdownRemark(fields: { id: { eq: "home" } }) {
      ...PageMetaFragmentMarkdown
    }
    config: configYaml {
      dateRow
      whereRow

      artistTitle

      storeButton
      storeText
      storeTitle

      donateTitle
      donateText
      donateButtonText
      donateButtonLink

      emailButton
      emailText
      emailTitle
    }
  }
`
