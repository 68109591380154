import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'


const FormContainer = styled.form`
  display: flex;
  max-width: 525px;
  width: 100%;
  position: relative;
  flex-direction: column;

  ${({ theme }) => theme.media.largePhone`
    flex-wrap: wrap;
  `}
`

const InputField = styled.input`
  outline: none;
  border: 0;
  margin: 0;
  width: 100%;
  line-height: 40px;
  padding: 15px 15px;
  z-index: 2;
  color: rgba(35, 97, 109, 0.8);
  background-color: ${({theme}) => theme.colors.purple};
  color: white;
  font-size: 20px;
  text-align: center;
  ::placeholder {
    color: ${({theme}) => theme.colors.white}20;
  }
`
const InputButton = styled.input`
  outline: none;
  border: 0;
  margin: 10px 0 0 0 ;
  padding: 15px 15px;
  display: block;
  color: white;
  background-color: ${({theme}) => theme.colors.purple};

`

export const Email = ({ emailButton }) => {
  const formName = 'newsletter'
  const [isSending, setIsSending] = useState(false)
  const [formData, setFormData] = useState({});

  const setFormValue = (name, value) => {
    setFormData(({ formData }) => {
      return {
        ...formData,
        [name]: value,
      }
    })
  }

  const encode = (data) =>
    Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&')

  const handleChange = (e) => {
    setFormValue(e.target.name, e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (isSending) return
    else setIsSending(true)
    debugger
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': formName, ...formData }),
    })
      .then(() => {
        setIsSending(false)
        navigate('/thanks/')
      })
      .catch((error) => {
        setIsSending(false)
      })
  }



  return (
    <FormContainer
      name={formName}
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value={formName} />
      <InputField
        name="email"
        type="email"
        placeholder="email address"
        required={true}
        onChange={handleChange}
      />

      <InputButton
        type="submit"
        value={emailButton || 'Sign up'}
        disabled={isSending}
      />
    </FormContainer>
  )
}
