import React from 'react'
import { Button } from '../components/Common.Button'
import { Image } from '../components/Common.Image'
import PopCryptBasic from '../../static/assets/popcrypt_basic.png'
import PopCryptPro from '../../static/assets/popcrypt_pro.png'

import './Store.css'

export const Store = () => {
  return (
    <div className="Store">
      <div className="Store-item">
        <Image src={PopCryptBasic} alt="Basic Pack -  Zoom Code + Compilation" width="200px" />
        <span> Zoom Code + Compilation</span>
        <a
          href="https://pcmusic.bandcamp.com/album/pop-crypt-skeleton-key"
          target="_blank"
          rel="noreferrer"
        >
          <Button> Level 0</Button>
        </a>
      </div>

      <div className="Store-item" disabled>
        <Image
          src={PopCryptPro}
          alt="Pro Pack -  Zoom Code + Compilation + Limited T-Shirt"
          width="200px"
        />
        <span> Zoom Code + Compilation + Limited T-Shirt</span>
        <a
          href="https://pcmusic.bandcamp.com/album/pop-crypt-skeleton-key"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Level -1</Button>
        </a>
      </div>
    </div>
  )
}
