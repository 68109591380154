import React from 'react'

import './VideoContainer.css'
import dd from '../../static/assets/LoopTall.mp4.gif'

export const VideoContainer = () => {
  return (
    <div className="video-container">
      <div className="twitch">
        <img
          src={dd}
          style={{
            width: '100%',
            position: 'absolute',
            marginTop: '-100%',
          }}
        />
        <div className="twitch-video" style={{}}>
          <iframe
            src="https://www.youtube.com/embed/P3OVPwRYG2Q?autoplay=1"
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            height="100%"
            width="100%"
            title="twitch pcmusicinfo stream"
            autoplay={true}
          ></iframe>
        </div>
      </div>
    </div>
  )
}
