import React from 'react'
import { lineRadial, select } from "d3";
import { primeNumbers } from './primeNumbers'
import './Primes.css'

const isBrowser = typeof window !== `undefined`

var radialLineGenerator = lineRadial();
var points = [];
var angle = 0;
var currentForm = 0

const primeForms = [
  [(currentNumber) => Math.PI  * -0.151, (currentNumber) => Math.PI  * 0.225],
  [(currentNumber) => Math.PI  * 2.25 * currentNumber, (currentNumber) => Math.PI  * 0.2 - currentNumber],
  [(currentNumber) => Math.PI  * 0.15, (currentNumber) => Math.PI  * 0.1],
  [(currentNumber) => Math.PI  * Math.sin(currentNumber), (currentNumber) => Math.PI  * 0.51],
  
  [(currentNumber) => Math.PI  * Math.cos(currentNumber), (currentNumber) => Math.PI  * 0.01],
  [(currentNumber) => Math.PI  * 0.45, (currentNumber) => Math.PI  * -0.11] ,
  [(currentNumber) => Math.PI  * -0.151, (currentNumber) => Math.PI  * 0.625],
  [(currentNumber) => Math.PI  * Math.cos(currentNumber), (currentNumber) => Math.PI * Math.cos(currentNumber)],
  [(currentNumber) => Math.PI  * Math.tan(currentNumber), (currentNumber) => Math.PI  * -0.1],
  [(currentNumber) => Math.PI  * -0.25, (currentNumber) => Math.PI  * 0.9],
  
]

export const Primes = () => {
  if (isBrowser) {
    const addPoint = () => {
      let currentNumber = points.length + 1;

      if (currentNumber === 500) {
        currentNumber = 0
        currentForm = (currentForm + 1) % primeForms.length
        points = []
        
        select('.primes')
          .attr('invert', currentForm%2);
      } 
      
      if (primeNumbers[currentNumber]) {
        angle =  angle + primeForms[currentForm][0](currentNumber)
      }
      else {
        angle = angle + primeForms[currentForm][1](currentNumber)
      }

      points.push([angle, currentNumber / 0.5])

      var radialLine = radialLineGenerator(points);
      
      select('g path')
        .attr('d', radialLine);

      
      window.requestAnimationFrame(() => setTimeout(addPoint, 16))
    }

    addPoint()
  }

  return (
    <section className="primes">
      <svg>
        <g>
          <path/>
        </g>
      </svg>
    </section>
  )
}
