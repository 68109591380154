import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

const isBrowser = typeof window !== `undefined`

export const ArtistsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  width: 90%;
  padding: 0;
`
const ArtistsElement = styled.li`
  width: 33%;
  margin: 10px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.purple};

  position: relative;
  font-size: 1.5em;
  transition: 200ms;

  ${({ theme }) => theme.media.largePhone`

    width: 50%;
  `};
`
const Name = styled.h2`
  font-weight: 600;

  margin: 5px;
  padding: 5px;
  display: block;
  text-shadow: 0px 0px 20px black;
  
  text-align: ${({ align }) => (align ? align : 'left')};
  
  ${({ theme }) => theme.media.tablet`
    font-size: 22px;
  `};

  ${({ theme }) => theme.media.largePhone`
    font-size: 20px;
  `};

  tablet
`

const letters = ['┋', '├', '┰', '╠', '╰', '}', '▜' , '░'];
let elements = [];
let changes = [];
var tick = false;

const randomLetter = () => letters[Math.floor(Math.random() * letters.length)]

const changeRandomLetter = () => {
  const element = elements[Math.floor(Math.random() * elements.length)];
  const innerLength = element.innerText.length;
  const offset = Math.floor(Math.random() * innerLength);
  const originalLetter = element.innerText[offset];
  const originalText = element.innerText;

  if (letters.includes(originalLetter)) return;
  if (originalLetter === ' ') return;

  changes.push({
    element,
    offset,
    originalLetter,
    originalText
  })

  element.innerText = element.innerText.substring(0, offset) + randomLetter() + element.innerText.substring(offset+1);
}

const resetLetter = () => {
  if(changes.length === 0) return

  const {element, originalText } = changes.pop();
  element.innerText = originalText
}

export function Artist({ artistName }) {

  return (
    <ArtistsElement key={artistName}>
      <Name align="center">{artistName}</Name>
    </ArtistsElement>
  )
}

export const Artists = () => {
  const { config } = useStaticQuery(graphql`
    query ArtistsQuery {
      config: configYaml {
        artists
      }
    }
  `)



  useEffect(() => {
    setInterval(resetLetter, 50);
    elements = Array.from(document.querySelectorAll('#artists h2'));
    if (isBrowser) {
      window.addEventListener('scroll', (e) => {
        if (!tick) {
          setTimeout(function () {
            changeRandomLetter();
            tick = false;
          }, 1)
        }
        tick = true;
      });
    }

  });

  return (
    <React.Fragment>
      <ArtistsContainer id="artists">
        {config.artists.map((artistName, index) => (
          <Artist key={index} artistName={artistName} />
        ))}
      </ArtistsContainer>
    </React.Fragment>
  )
}
